<template>
  <CRow>
    <CCol sm="4">
      <CSelect
          label="大分類"
          horizontal
          v-model="selectedCategory.major_category"
          :options="major_category"
          @change="(e) => selectChange(e, 'major_category')"
          :placeholder="placeholder"
          :invalid-feedback="errors && errors.major_category ? errors.major_category[0] : ''"
          :is-valid="errors && errors.major_category ? false : null"
      />
    </CCol>
    <CCol sm="4">
      <CSelect
          label="中分類"
          horizontal
          v-model="selectedCategory.middle_category"
          :options="middle_category"
          @change="(e) => selectChange(e, 'middle_category')"
          :placeholder="placeholder"
          :invalid-feedback="errors && errors.middle_category ? errors.middle_category[0] : ''"
          :is-valid="errors && errors.middle_category ? false : null"
      />
    </CCol>
    <CCol sm="4">
      <CSelect
          label="小分類"
          horizontal
          v-model="selectedCategory.minor_category"
          :options="minor_category"
          @change="(e) => selectChange(e, 'minor_category')"
          :placeholder="placeholder"
          :invalid-feedback="errors && errors.minor_category ? errors.minor_category[0] : ''"
          :is-valid="errors && errors.minor_category ? false : null"
      />
    </CCol>
  </CRow>
</template>

<script>

import ResourceApi from '../../api/resourceApi';

const apiMajorCategories = new ResourceApi('major-categories');
const apiMiddleCategories = new ResourceApi('middle-categories');
const apiMinorCategories = new ResourceApi('minor-categories');

export default {
name: "CategoryComponent",
  props: {
    placeholder: String,
    label: String,
    errors:  {
      type:Object,
      default: () => {return {}}
    },
    defaultValue: {
      type:Object,
      default: () => {return {}}
    }
  },
  data() {
      return {
        selectedCategory: this.defaultValue || {
          major_category: '',
          middle_category: '',
          minor_category: '',
        },
        keyChange: 0,
        major_category: [],
        middle_category: [],
        minor_category: [],
        init: true,
      }
    },
  watch: {

  },
  created(){
    this.getAllData();
  },
  methods: {
    selectChange (val, key) {
      this.selectedCategory[key] = val.target.value;
      this.$emit('change', this.selectedCategory)
      // this.getAllData(value, key)
    },
    getMajorCategory() {
      apiMajorCategories.all().then(response => {
        this.major_category = response.data.data;
        this.major_category.forEach(major => {
          major.label = major.name
          major.value = major.id
        })
        this.major_category.unshift({'value': '', 'label': '選択する'})
      }).catch(error => {})
    },

    getMiddleCategory() {
      apiMiddleCategories.all().then(response => {
        this.middle_category = response.data.data;
        this.middle_category.forEach(middle => {
          middle.label = middle.name
          middle.value = middle.id
        })
        this.middle_category.unshift({'value': '', 'label': '選択する'})
      }).catch(error => {})
      console.log(this.middle_category)
    },

    getMinorCategory() {
      apiMinorCategories.all().then(response => {
        this.minor_category = response.data.data;

        this.minor_category.forEach(minor => {
          minor.label = minor.name
          minor.value = minor.id
        })
        this.minor_category.unshift({'value': '', 'label': '選択する'})
      }).catch(error => {})
    },

    getAllData () {
      this.loading = true
      this.getMajorCategory()
      this.getMiddleCategory()
      this.getMinorCategory()
      this.loading = false
    },
    }
}
</script>

<style scoped>

</style>