<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader> 在庫 </CCardHeader>
        <vue-confirm-dialog></vue-confirm-dialog>

        <CCardBody>
          <!--START FILTER-->
          <!--SCREEN_KEY.LIST_INVENTORY || SCREEN_KEY.LIST_STOCKS-->
          <div>
            <CRow>
              <CCol sm="3">
                <CInput
                  id="arrivedat"
                  label="入荷日"
                  type="date"
                  horizontal
                  v-model="filterDate"
                />
              </CCol>
              <CCol sm="3" v-if="screen_key === SCREEN_KEY.LIST_INVENTORY">
                <SelectComponent
                  id="status"
                  label="状態"
                  :default-options="statusOptions"
                  @change="changeStatus"
                  :default-value="statusSelected"
                />
              </CCol>
              <CCol sm="3">
                <SelectComponent
                  id="year"
                  label="年度"
                  :default-options="businessYearOptions"
                  @change="changeBusinessYear"
                  :default-value="businessYear"
                  placeholder="選択する"
                />
              </CCol>
              <CCol sm="3">
                <SelectComponent
                  id="month"
                  label="展開月"
                  :default-options="businessMonthOptions"
                  @change="changeBusinessMonth"
                  :default-value="businessMonth"
                  placeholder="選択する"
                />
              </CCol>
              <CCol sm="3">
                <SelectComponent
                  id="shelf"
                  label="棚"
                  end-point="shelves"
                  @change="changeShelves"
                  :default-value="shelvesId"
                  placeholder="選択する"
                />
              </CCol>
              <CCol sm="3">
                <SelectComponent
                  id="color"
                  label="色"
                  :default-options="colorOptions"
                  @change="changeColor"
                  :default-value="colorId"
                  placeholder="選択する"
                />
              </CCol>
              <CCol sm="3">
                <SelectComponent
                  id="size"
                  label="サイズ"
                  end-point="sizes"
                  @change="changeSize"
                  :default-value="sizeId"
                  placeholder="選択する"
                />
              </CCol>
              <CCol sm="3">
                <SelectComponent
                  id="season"
                  label="シーズン"
                  end-point="seasons"
                  @change="changeSeasons"
                  :default-value="season"
                  placeholder="選択する"
                />
              </CCol>
              <CCol sm="3">
                <SelectComponent
                  id="ppbs"
                  label="PP/BS"
                  :default-options="ppbsOptions"
                  @change="changePPBS"
                  :default-value="ppbs"
                  placeholder="選択する"
                />
              </CCol>
              <CCol sm="3">
                <SelectComponent
                  id="majorcategory"
                  label="大分類"
                  end-point="major-categories"
                  @change="changeMajorCategory"
                  :default-value="majorCategoryId"
                  placeholder="選択する"
                />
              </CCol>
              <CCol sm="3">
                <SelectComponent
                  id="middlecategory"
                  label="中分類"
                  end-point="middle-categories"
                  @change="changeMiddleCategory"
                  :default-value="middleCategoryId"
                  placeholder="選択する"
                />
              </CCol>
              <CCol sm="3">
                <SelectComponent
                  id="minorcategory"
                  label="小分類"
                  end-point="minor-categories"
                  @change="changeMinorCategory"
                  :default-value="minorCategoryId"
                  placeholder="選択する"
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  id="itemcode"
                  label="品番"
                  type="text"
                  horizontal
                  v-model="itemCode"
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  id="itemid"
                  label="商品ID"
                  type="text"
                  horizontal
                  v-model="itemId"
                />
              </CCol>
              <CCol sm="3" v-if="userShopId === WAREHOUSUE && screen_key === SCREEN_KEY.LIST_INVENTORY">
                <SelectComponent
                  id="shop"
                  label="店舗"
                  :default-options="shopOptions"
                  @change="selectShopChange"
                  :default-value="shopSelected"
                />
              </CCol>
              <CCol sm="3">
                <SelectComponent
                  id="special"
                  label="優待"
                  :default-options="specialOptions"
                  @change="changeSpecial"
                  :default-value="is_special"
                  placeholder="選択する"
                />
              </CCol>
            </CRow>

            <CRow class="mb-3">
              <CCol sm="10">
                <CButton color="primary" class="mr-2" @click="filterData">検索</CButton>
                <CButton color="danger" @click="resetFilterData">取り消す</CButton>
              </CCol>
            </CRow>
          </div>
          <CRow class="text-right mb-2" v-if="[SCREEN_KEY.LIST_INVENTORY, SCREEN_KEY.LIST_STOCKS].includes(screen_key)">
            <CCol sm="12">
              <CButton v-if="userShopId === WAREHOUSUE" type="submit" color="primary" class="mr-2" @click="handleQRDownload">
                ラベル印刷
              </CButton>
              <CButton v-if="userShopId === WAREHOUSUE" color="primary" class="mr-2" @click="handleCsvDownload">
                CSVダウンロード
              </CButton>
              <CButton v-if="userShopId === WAREHOUSUE" color="primary" class="mr-2" @click="clickImport">
                CSVアップロード
              </CButton>
              <CButton v-if="userShopId === WAREHOUSUE" color="primary" class="mr-2" @click="clickSchedule">
                CSV予約アップロード
              </CButton>
              <CButton v-if="userShopId === WAREHOUSUE" @click="viewCreateStock" type="submit" color="primary" class="mr-2">
                入荷登録
              </CButton>
              <!--              <CButton v-if="userShopId !== WAREHOUSUE" @click="renderScanQR" type="submit" color="primary"-->
              <!--                       class="mr-2">検品-->
              <!--              </CButton>-->
            </CCol>
          </CRow>

          <!--SCREEN_KEY.LIST_SHIPPING-->
          <div class="row my-2 mx-0"
              :class="{'mt-5': [SCREEN_KEY.LIST_RETURN, SCREEN_KEY.LIST_SHIPPING].includes(screen_key)}">
            <div class="col-sm-6 form-inline p-0 select-shop"
                v-if="[SCREEN_KEY.LIST_RETURN, SCREEN_KEY.LIST_SHIPPING].includes(screen_key)">
              <SelectComponent
                :default-options="shopOptions"
                @change="selectShopChange"
                :default-value="shopSelected"
              />
              <CButton v-if="screen_key === SCREEN_KEY.LIST_SHIPPING" :disabled="!shopSelected || !listSelected.length"
                @click="handleConfirmChangeStatus('shipping')" color="primary" type="submit"
                class="mr-2 ml-3">出荷する
              </CButton>
              <CButton v-if="screen_key === SCREEN_KEY.LIST_RETURN" :disabled="!shopSelected || !listSelected.length"
                @click="handleConfirmChangeStatus('return')" type="submit" color="primary"
                class="mr-2 ml-3">返品
              </CButton>
            </div>

            <div class="col-sm-6 p-0"
              :class="{ 'offset-sm-6': ![SCREEN_KEY.LIST_RETURN, SCREEN_KEY.LIST_SHIPPING].includes(screen_key) }">

              <div class="form-inline justify-content-sm-end">
                <label class="mr-2">表示件数</label>
                <select
                    class="form-control"
                    @change="selectChangePerpage"
                    aria-label="changes number of visible items"
                >
                  <option value="" selected disabled hidden>
                    {{ paginationData.per_page }}
                  </option>
                  <option
                      v-for="(number, key) in [5, 10, 20, 30, 50, 100]"
                      :val="number"
                      :key="key"
                  >
                    {{ number }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!--SCREEN_KEY.LIST_RETURN-->
          <div v-if="loading">
            <LoadingCompoment />
          </div>
          <div v-else :key="key">
            <DataTable
              hover
              striped
              border
              :items="stocks"
              :fields="fields"
              :items-per-page="perPage"
              :active-page="page"
              :pagination-data="paginationData"
              @page-change="pageChange"
              @pagination-change="selectChangePerpage"
              :pagination="{ doubleArrows: false, align: 'center' }"
            >
              <div slot="select-header">
                <CInputCheckbox
                  id="checkAll"
                  label=""
                  class="pb-3"
                  name="listSelected"
                  @update:checked="handleCheckAll"
                  :checked="checkAll"
                />
              </div>
              <td slot="select" slot-scope="{ item }">
                <div>
                  <CInputCheckbox
                    id="checkBox"
                    :key="item.id"
                    label=""
                    :value="item.item"
                    name="listSelected"
                    @update:checked="handleSelect(item)"
                    :checked="listSelected.includes(item.id)"
                  />
                </div>
              </td>
              <!--              <td slot="stock_in_date" slot-scope="{item}">-->
              <!--                <div> {{ item.stock_in_date }}</div>-->
              <!--              </td>-->
              <!--              <td slot="stock_out_date" slot-scope="{item}">-->
              <!--                <div> {{ item.stock_out_date }}</div>-->
              <!--              </td>-->
              <!--              <td slot="status" slot-scope="{item}">-->
              <!--                <div> {{ stockStatus[item.status] }}</div>-->
              <!--              </td>-->
              <td slot="shop_name" slot-scope="{ item }">
                {{ item.shop.name }}
              </td>
              <td slot="status" slot-scope="{ item }">
                {{ item.status_new }}
              </td>
              <td slot="shelf_id" slot-scope="{ item }">
                {{ item.shelf.name }}
              </td>
              <td slot="item_masters_code" slot-scope="{ item }">
                {{ item.item.item_master.item_code || "" }}
              </td>
              <td slot="item_masters_name" slot-scope="{ item }">
                {{ item.item.item_master.name || "" }}
              </td>
              <td slot="item_masters_size" slot-scope="{ item }">
                {{ item.item.item_master.size.name || "" }}
              </td>
              <td slot="item_masters_color" slot-scope="{ item }">
                {{ item.color_name || "" }}
              </td>
              <td
                slot="item_masters_cost"
                slot-scope="{ item }"
                class="text-right"
              >
                {{ item.item_masters_cost || "" }}
              </td>
              <td
                slot="item_masters_price"
                slot-scope="{ item }"
                class="text-right"
              >
                {{ item.price || "" }}
              </td>
              <td
                slot="item_masters_retail_price"
                slot-scope="{ item }"
                class="text-right"
              >
                {{ item.item_masters_retail_price || "" }}
              </td>
              <td slot="pp_bs_code" slot-scope="{ item }">
                {{ item.pp_bs_code || item.item.item_master.pp_bs_code }}
              </td>
              <td slot="is_special" slot-scope="{ item }">
                {{ item.special || "" }}
              </td>
              <td slot="action" slot-scope="{ item }" class="text-center">
                <CButton color="primary" @click="goToDetailPage(item)">
                  <CIcon name="cil-pencil" />
                </CButton>
              </td>
            </DataTable>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { STOCK_STATUS } from "@/utils/constance"
import ResourceApi from "../../api/resourceApi"
import apiStockOther from "../../api/stockApi"
import Vue from "vue"
import DataTable from "@/components/DataTable"
import Stocks from "@/views/stocks/StockFields"
import { ALL_SHOPS, WAREHOUSUE, SCREEN_KEY, NORMAL_STOCK } from "@/utils/constance"
import SelectComponent from "@/components/views/SelectComponent"
import { setOptionBusinessMonth, setOptionBusinessYear, numberWithCommas, setOptionColors } from "@/utils/utils"
import CategoryComponent from "@/components/views/CategoryComponent"

const apiStocks = new ResourceApi("stocks")
const apiShop = new ResourceApi("shops")

export default {
  name: "Stocks",
  components: { CategoryComponent, SelectComponent, DataTable },
  props: ["screen_key"],

  data() {
    return {
      stockStatus: STOCK_STATUS,
      WAREHOUSUE: WAREHOUSUE,
      SCREEN_KEY: SCREEN_KEY,
      ALL_SHOPS: ALL_SHOPS,
      stocks: [],
      loading: false,
      checkAll: false,
      filterDate: "",
      shelvesId: "",
      businessYear: "",
      businessMonth: "",
      categories: {},
      categorySelected: "",
      season: "",
      colorId: "",
      sizeId: "",
      userShopId: this.$store.getters.user
        ? this.$store.getters.user.shop_id
        : "",
      listSelected: [],
      shopOptions: [],
      statusSelected: NORMAL_STOCK,
      typeDataSelected: 1,
      shopSelected: "",
      paginationData: {},
      page: 1,
      perPage: 30,
      query: {
        page: this.page,
        per_page: this.perPage,
        screen_key: this.screen_key,
      },
      key: Math.random(),
      keyCategory: Math.random(),
      businessYearOptions: [],
      businessMonthOptions: [],
      ppbs: "",
      ppbsOptions: [
        { label: "選択する", value: "" },
        { label: "PP", value: "PP" },
        { label: "BS", value: "BS" },
      ],
      price: "",

      // options: [
      //   {label: '選択する', value: ''},
      //   {label: '入荷', value: 1},
      //   {label: '出荷', value: 2},
      //   {label: '返品', value: 3},
      //   {label: '入荷[済]', value: 4},
      //   // {label: '出荷[済]', value: 5},
      //   {label: '返品[済]', value: 6},
      // ],
      statusOptions: [
        { label: "通常在庫", value: 1 },
        { label: "不良在庫", value: 4 },
        { label: "全て", value: "" },
      ],
      dateOptions: [
        { label: "入荷日", value: 2 },
        { label: "出荷日", value: 3 },
      ],
      optionsPerPage: [10, 20, 30, 40, 50, 100],
      fields: Stocks.fields(),
      activePage: 1,
      majorCategoryId: "",
      middleCategoryId: "",
      minorCategoryId: "",
      itemId: "",
      itemCode: "",
      colorOptions: [],
      is_special: "",
      specialOptions: [
        { label: "選択する", value: "" },
        { label: "非優待", value: 0 },
        { label: "優待", value: 1 },
      ],
    }
  },

  watch: {
    listSelected: function (newVal, oldVal) {
      let checkAll = true
      this.stocks.map((s) => {
        if (!newVal.includes(s.id)) {
          checkAll = false
        }
      })
      this.checkAll = checkAll
    },

    stocks: function (newVal, oldVal) {
      let checkAll = true
      this.stocks.map((s) => {
        if (!this.listSelected.includes(s.id)) {
          checkAll = false
        }
      })
      this.checkAll = checkAll
    },
  },

  created() {
    this.businessYearOptions = setOptionBusinessYear()
    this.businessMonthOptions = setOptionBusinessMonth()
    this.colorOptions = setOptionColors()
    if (
      this.screen_key === SCREEN_KEY.LIST_SHIPPING ||
      this.screen_key === SCREEN_KEY.LIST_RETURN
    ) {
      this.getShops()
    } else if (
      this.screen_key === SCREEN_KEY.LIST_INVENTORY &&
      this.userShopId === WAREHOUSUE
    ) {
      this.setShopOptions()
    }
    this.setParams()
    let param = {
      page: this.page,
      per_page: this.perPage,
      status: this.statusSelected
    }
    if(this.userShopId === WAREHOUSUE) {
      param["shop_id"] = this.shopSelected
    }
    let params = this.getParams(param)
    this.getData(params)
  },

  beforeRouteEnter (to, from, next) {
    if (from.name !== "Stock Details") {
      sessionStorage.removeItem('stocks')
    }
    next()
  },

  methods: {
    getShops() {
      // this.resetSelected()
      this.loading = true

      apiShop
        .all({ screen_key: SCREEN_KEY.LIST_STOCKS })
        .then((response) => {
          // console.log(response.data)
          let shops = []
          response.data.data.map((s) => {
            switch (this.screen_key) {
              case SCREEN_KEY.LIST_RETURN:
                if (s.id === WAREHOUSUE) {
                  shops.push({ label: s.name, value: s.id })
                }
                break
              case SCREEN_KEY.LIST_SHIPPING:
                if (s.id !== WAREHOUSUE) {
                  shops.push({ label: s.name, value: s.id })
                }
                break
              default:
            }
          })
          if (shops.length === 1) {
            this.shopSelected = parseInt(shops[0].value)
          }
          this.shopOptions = shops
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
        })
    },

    getData(params) {
      // this.resetSelected()
      this.loading = true
      this.key = Math.random()
      this.setStorage()
      this.query = { ...this.query, ...params }
      apiStocks
        .index(this.query)
        .then((response) => {
          // console.log(response.data)
          let stocks = response.data.data
          stocks.map((s) => {
            s.item_masters_cost = numberWithCommas(s.item.item_master.cost)
            s.item_masters_price = numberWithCommas(s.item.item_master.price)
            s.item_masters_retail_price = numberWithCommas(s.item.item_master.retail_price)
            s.color_name = s.item.item_master.color.color_code + ':' + s.item.item_master.color.name
            if(s.price) {
              s.price = numberWithCommas(s.price)
            } else {
              s.price = numberWithCommas(s.item.item_master.price)
            }
          })
          this.stocks = stocks
          this.paginationData = response.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
        })
    },

    setStorage() {
      let param = {
        page: this.page,
        per_page: this.perPage
      }
      let params = this.getParams(param)
      sessionStorage.setItem('stocks', JSON.stringify(params))
    },

    handleCheckAll(val) {
      let listSelected = this.listSelected
      if (this.checkAll) {
        this.stocks.map((s) => {
          listSelected = listSelected.filter((l) => s.id !== l)
        })
      } else {
        this.stocks.map((s) => {
          listSelected.push(s.id)
        })
      }
      this.listSelected = listSelected
    },

    handleConfirmChangeStatus(action) {
      let message = ""
      if (this.screen_key === SCREEN_KEY.LIST_SHIPPING) {
        message = `出荷を行いますか？`
      }
      if (this.screen_key === SCREEN_KEY.LIST_RETURN) {
        message = `返品を実行して宜しいですか？`
      }

      this.$confirm({
        title: "確認",
        message: message,
        button: {
          no: "いいえ",
          yes: "はい",
        },
        callback: (confirm) => {
          if (confirm) {
            // ... do something
            this.handleChangeStatus(action)
          }
        },
      })
    },

    handleChangeStatus(action) {
      let list = this.listSelected
      let listStr = list.join()
      let params = {
        shop_id: this.shopSelected,
        list_id_str: listStr,
        action,
      }
      apiStockOther
        .changeStatusStock(params)
        .then((response) => {
          Vue.$toast.success("出荷が完了しました。")
          this.getData()
        })
        .catch((error) => {
          // Vue.$toast.error('Error')
        })
        .finally(() => {
          this.loading = false
        })
    },

    handleSelect(item) {
      if (this.listSelected.includes(item.id)) {
        this.listSelected = this.listSelected.filter((s) => s !== item.id)
      } else {
        this.listSelected.push(item.id)
      }
    },

    clickImport() {
      this.$router.push({ path: `stocks/import` })
    },

    clickSchedule() {
      this.$router.push({ path: `stocks/schedule` })
    },

    filterData() {
      this.page = 1
      let params = this.getParams({ page: 1 })
      this.getData(params)
    },

    getParams(param) {
      let params = {
        type_date: this.typeDataSelected || null,
        status: this.statusSelected || null,
        date_filter: this.filterDate || null,
        screen_key: this.screen_key,
        business_year: this.businessYear,
        season_id: this.season,
        shelf_id: this.shelvesId,
        month: this.businessMonth,
        ppbs: this.ppbs,
        size_id: this.sizeId,
        color_id: this.colorId,
        major_category: this.majorCategoryId,
        middle_category: this.middleCategoryId,
        minor_category: this.minorCategoryId,
        item_id: this.itemId,
        item_code: this.itemCode,
        is_special: this.is_special,
        price: this.price,
      }

      if (this.userShopId === WAREHOUSUE) {
        params["shop_id"] = parseInt(this.shopSelected)
      }

      return { ...params, ...param }
    },

    setParams() {
      let params = sessionStorage.getItem('stocks') ? JSON.parse(sessionStorage.getItem('stocks')) : {}
      if(Object.keys(params).length) {
        this.page = params.page
        this.perPage = params.per_page
        this.typeDataSelected = params.type_date
        this.statusSelected = params.status
        this.filterDate = params.date_filter
        this.screen_key = params.screen_key
        this.businessYear = params.business_year
        this.season = params.season_id
        this.shelvesId = params.shelf_id
        this.businessMonth = params.month
        this.ppbs = params.ppbs
        this.sizeId = params.size_id
        this.colorId = params.color_id
        this.majorCategoryId = params.major_category
        this.middleCategoryId = params.middle_category
        this.minorCategoryId = params.minor_category
        this.itemId = params.item_id
        this.itemCode = params.item_code
        this.is_special = params.is_special
        this.price = params.price

        if (this.userShopId === WAREHOUSUE) {
          this.shopSelected = params.shop_id
        }
      }
    },

    resetFilterData() {
      this.statusSelected = NORMAL_STOCK
      this.typeDataSelected = ""
      this.filterDate = ""
      this.shelvesId = ""
      this.businessYear = ""
      this.season = ""
      this.businessMonth = ""
      this.ppbs = ""
      this.sizeId = ""
      this.colorId = ""
      this.listSelected = []
      this.majorCategoryId = ""
      this.middleCategoryId = ""
      this.minorCategoryId = ""
      this.itemId = ""
      this.itemCode = ""
      // this.categories = {}
      // this.keyCategory = Math.random()
      this.is_special = ""
      this.price = ""
      let params = {
        status: NORMAL_STOCK,
        date_filter: "",
        shelf_id: "",
        business_year: "",
        category_id: "",
        season_id: "",
        major_category: "",
        middle_category: "",
        minor_category: "",
        color_id: "",
        size_id: "",
        month: "",
        ppbs: "",
        is_special: "",
        price: "",
        item_id: "",
        item_code: "",
      }

      if (this.userShopId === WAREHOUSUE) {
        this.shopSelected = ALL_SHOPS
        params["shop_id"] = ALL_SHOPS
      }

      this.getData(params)
    },

    changeStatus(val) {
      this.statusSelected = val ? parseInt(val) : ''
    },
    changeShelves(val) {
      this.shelvesId = val ? parseInt(val) : ''
    },
    changeSeasons(val) {
      this.season = val ? parseInt(val) : ''
    },
    changeColor(val) {
      this.colorId = val ? parseInt(val) : ''
    },
    changeSize(val) {
      this.sizeId = val ? parseInt(val) : ''
    },
    changePPBS(val) {
      this.ppbs = val
    },
    changeBusinessYear(val) {
      this.businessYear = val ? parseInt(val) : ''
    },
    changeBusinessMonth(val) {
      this.businessMonth = val ? parseInt(val) : ''
    },
    changeMajorCategory(val) {
      this.majorCategoryId = val ? parseInt(val) : ''
    },
    changeMiddleCategory(val) {
      this.middleCategoryId = val ? parseInt(val) : ''
    },
    changeMinorCategory(val) {
      this.minorCategoryId = val ? parseInt(val) : ''
    },
    changeSpecial(val) {
      this.is_special = val ? parseInt(val) : ''
    },
    selectShopChange(val) {
      if(this.screen_key !== SCREEN_KEY.LIST_INVENTORY)
        this.shopSelected = val.target.value ? parseInt(val.target.value) : ''
      else {
        this.shopSelected = val;
      }
    },

    selectChangePerpage(val) {
      this.perPage = val.target.value ? parseInt(val.target.value) : ""
      this.getData({ per_page: val.target.value })
    },

    handleCsvDownload() {
      this.loading = true
      let list = this.listSelected
      let listStr = list.join()
      let params = this.getParams({ list_id_str: listStr })

      apiStockOther
        .csvDownload(params)
        .then((response) => {
          Vue.$toast.success("ダウンロード成功")
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement("a")
          fileLink.href = fileURL
          let timeCurrent = new Date()
          fileLink.setAttribute(
            "download",
            "stocks-" + timeCurrent.toLocaleString() + ".csv"
          )
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch((error) => {
          Vue.$toast.error("ダウンロードに失敗しました")
        })
        .finally(() => {
          this.loading = false
        })
    },

    handleQRDownload() {
      this.loading = true
      let list = this.listSelected
      let listStr = list.join()
      if (!listStr.length) {
        Vue.$toast.error("商品を選択してください")
        this.loading = false
        return
      }
      let params = this.getParams({ list_id_str: listStr })
      apiStockOther
        .generateQR(params)
        .then((response) => {
          Vue.$toast.success("QRコードの印刷に成功しました")
          // let path = response.data.path
          // setTimeout(() => window.open(path, '_blank'), 100)
          // Vue.$toast.success('ダウンロード成功')
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement("a")
          fileLink.href = fileURL
          let timeCurrent = new Date()
          fileLink.setAttribute(
            "download",
            "stocks-" + timeCurrent.toLocaleString() + ".pdf"
          )
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch((error) => {
          Vue.$toast.error("ダウンロードに失敗しました")
        })
        .finally(() => {
          this.loading = false
        })
    },

    pageChange(val) {
      this.page = val
      this.getData({ page: val })
      // this.checkAll = false;
      // this.$router.push({ query: { page: val }})
    },

    viewCreateStock() {
      this.$router.push({ path: `stocks/create` })
    },

    renderScanQR() {
      this.$router.push({ path: `stocks/scan-qr` })
    },

    goToDetailPage(item) {
      this.$router.push({ path: `stocks/${item.id}` })
    },

    setShopOptions() {
      this.shopSelected = ALL_SHOPS
      this.shopOptions.push({ label: "全店舗", value: ALL_SHOPS })
      apiShop.all({ screen_key: SCREEN_KEY.LIST_USERS }).then((response) => {
        response.data.data.map((shop) => {
          this.shopOptions.push({ label: shop.name, value: shop.id })
        })
      })
    },
  },
}
</script>

<style>
/*.select-shop select{*/
/*  width: 400px !important;*/
/*}*/

@media screen and (min-width: 1500px) {
  .select-shop select {
    width: 400px !important;
  }
}
</style>
